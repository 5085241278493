import React, { useRef } from 'react'; // Import useRef
import './BigDataAIPage.css';
import BigData from '../../assets/bigdata.jpeg'
import BigDataSub from '../../assets/bigdatasub.jpg'
import ContactForm from '../ContactForm/ContactForm';

function MobileDev() {
    const contactFormRef = useRef(null); // Create a reference

    const scrollToContact = () => {
        contactFormRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <div className="big-data-ai">
            <SplashScreen onContactClick={scrollToContact} />
            <DescriptionSection />
            <KeyFeatures />
            <ContactForm ref={contactFormRef} />
        </div>
    );
}

function SplashScreen({ onContactClick }) {
    return (
        <div className="splash3">
            <div className="splash-content">
                <h1 className='heading'>Cloud Migration <br></br>
Big Data & Analytics 
</h1>
                <p className='subheading'>Unlocking potential with intelligence and analytics</p>
                <div className="button-container">
                    <button onClick={onContactClick}>Contact Us</button>
                </div>
            </div>
        </div>
    );
}

function DescriptionSection() {
    return (
        <div className="description-section">
            <div className="text">
                <h2>Big Data & Analytics</h2>
                <p>Big data plays a crucial role in transforming digital businesses—improving customer engagement, solving key business issues, and uncovering opportunities for efficiency and growth.
                <br></br>
                <br></br>
To embark on this transformation, we consider critical questions such as: What specific business goals are we targeting? What obstacles do we need to address? What data do we have at our disposal? Is this data organized or disorganized? What new data should we collect? How can we maximize the value of this data? What technologies will help us utilize this data to strengthen and expand our business? We plan to integrate this methodology into our website.</p>
            </div>
            <div className="image">
                <img src={BigDataSub} alt="Big Data and AI" />
            </div>
        </div>
    );
}

function KeyFeatures() {
    const features = [
        {
            icon: "path_to_icon1",
            title: "ACTIONABLE INSIGHTS ECOSYSTEM",
            description: "Accelerate decision-making with real time access to actionable insights, BI reports, metrics, and KPIs."
        },
        {
            icon: "path_to_icon2",
            title: "BIG DATA CLOUD MIGRATION",
            description: "Leverage cloud services to enable data-driven decision making."
        },
        {
            icon: "path_to_icon3",
            title: "ENTERPRISE DATA PLATFORMS",
            description: "Increase employee and business productivity by reducing manual data processing work."
        },
        {
            icon: "path_to_icon4",
            title: "DATA STRATEGY AND GOVERNANCE",
            description: "Democratize data, ensure its quality, and accelerate data initiatives across the company."
        }
    ];

    return (
        <div className="features-section">
            <h2>What we do</h2>
            <div className="feature-grid-container">
                <div className="feature-grid">
                    {features.map((feature, index) => (
                        <div key={index} className="feature-item">
                            <h3>{feature.title}</h3>
                            <p>{feature.description}</p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default MobileDev;
