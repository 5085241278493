import React, { useRef } from 'react'; // Import useRef
import './HealthcarePage.css';
import BigData from '../../assets/bigdata.jpeg'
import BigDataSub from '../../assets/bigdatasub.jpg'
import ContactForm from '../ContactForm/ContactForm';
import hc from '../../assets/hc.jpg'

function HealthcarePage() {
    const contactFormRef = useRef(null); // Create a reference

    const scrollToContact = () => {
        contactFormRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <div className="big-data-ai">
            <SplashScreen onContactClick={scrollToContact} />
            <DescriptionSection />
            <KeyFeatures />
            <ContactForm ref={contactFormRef} />
        </div>
    );
}

function SplashScreen({ onContactClick }) {
    return (
        <div className="splash1">
            <div className="splash-content">
                <h1 className='heading'>Healthcare Solutions</h1>
                <p className='subheading'>Achieving efficient healthcare solutions with innovative technology</p>
                <div className="button-container">
                    <button onClick={onContactClick}>Contact Us</button>
                </div>
            </div>
        </div>
    );
}

function DescriptionSection() {
    return (
        <div className="description-section">
            <div className="text">
                <h2>Propelling Success With Healthcare Technology Solutions Initiatives</h2>
                <p>Supercharge your healthcare tech initiatives with precision-engineered solutions to catalyze care, deliver unmatched impact and technical excellence.</p>
            </div>
            <div className="image">
                <img src={hc} alt="Healthcare technology" />
            </div>
        </div>
    );
}

function KeyFeatures() {
    const features = [
        {
            icon: "path_to_icon1",
            title: "Provider",
            description: "Improve patient experience and personalize care."
        },
        {
            icon: "path_to_icon2",
            title: "Payer",
            description: "Care cost management and quality improvements"
        },
        {
            icon: "path_to_icon3",
            title: "Patient",
            description: "Affordability of care and quality of care"
        },
        {
            icon: "path_to_icon4",
            title: "Partner",
            description: "Improved health information exchange and improve care coordination and communication"
        }
    ];

    return (
        <div className="features-section">
            <h2>What we do</h2>
            <div className="feature-grid-container">  {/* Wrapper added */}
                <div className="feature-grid">
                    {features.map((feature, index) => (
                        <div key={index} className="feature-item">
                            <h3>{feature.title}</h3>
                            <p>{feature.description}</p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default HealthcarePage;
