import React, { useState, useEffect } from 'react';
import './TabBar.css';
import logoImage from '../../assets/logo.png'; // Make sure the path is correct
import { FaBars, FaTimes } from 'react-icons/fa'; // FontAwesome icons for the menu

function TabBar() {
    const [isServicesOpen, setServicesOpen] = useState(false);
    const [isIndustriesOpen, setIndustriesOpen] = useState(false);
    const [navBackground, setNavBackground] = useState('transparent');
    const [isMenuOpen, setMenuOpen] = useState(false);

    const handleScroll = () => {
        const show = window.scrollY > -1; // You can adjust this value according to your needs
        if (show) {
            setNavBackground('white');
        } else {
            setNavBackground('white');
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <nav className="navbar" style={{ backgroundColor: 'white' }}>
            <div className="logo">
                <a href="/"> {/* Wrap the image in an anchor tag pointing to the homepage */}
                    <img src={logoImage} alt="Logo" />
                </a>
            </div>
            <div className="menu-icon" onClick={() => setMenuOpen(!isMenuOpen)}>
                {isMenuOpen ? <FaTimes /> : <FaBars />}
            </div>
            <ul className={`menu ${isMenuOpen ? 'open' : ''}`}>
                <li className="menu-item"><a href="about">About Us</a></li>
                <li className="menu-item" onMouseEnter={() => setServicesOpen(true)} onMouseLeave={() => setServicesOpen(false)}>
                    Services
                    {isServicesOpen && (
                        <ul className="submenu">
                            <li><a href="bigdata">Big Data & Analytics</a></li>
                            <li><a href="genai">Generative AI</a></li>
                            <li><a href="aiml">AI & ML</a></li>
                        </ul>
                    )}
                </li>
                <li className="menu-item" onMouseEnter={() => setIndustriesOpen(true)} onMouseLeave={() => setIndustriesOpen(false)}>
                    Industries
                    {isIndustriesOpen && (
                        <ul className="submenu">
                            <li><a href="healthcare">Healthcare</a></li>
                            <li><a href="lifesciences">Life Sciences</a></li>
                        </ul>
                    )}
                </li>
                <li className="menu-item"><a href="contact">Contact</a></li>
            </ul>
        </nav>
    );
}

export default TabBar;
