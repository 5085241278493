import React from 'react';
import './FooterBar.css'; // Import the CSS
import Logo from '../../assets/logo.png'

function FooterBar() {
  return (
    <div className="footer-bar">
      <div className="footer-content">
        <div className="footer-logo">
          {/* Replace "logo.png" with the path to your logo image */}
          <img src={Logo} alt="Ethereon Logo" />
        </div>
        <div className="footer-info">
          <div className="footer-location">
            <strong>Location</strong>
            <p>1452 Hughes Road, Suite 200<br/>Grapevine, TX 76051</p>
          </div>
          <div className="footer-contact">
            <strong>Contact</strong>
            <p>info@ethereonai.com<br/>(214) 422-1535</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FooterBar;
