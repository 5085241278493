import React, { useRef } from 'react'; // Import useRef
import './MobileDev.css'; 
import app from '../../assets/mad.png';
import ContactForm from '../ContactForm/ContactForm';

function MobileDev() {
    const contactFormRef = useRef(null); // Create a reference

    const scrollToContact = () => {
        contactFormRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <div className="big-data-ai">
            <SplashScreen onContactClick={scrollToContact} />
            <DescriptionSection />
            <KeyFeatures />
            <ContactForm ref={contactFormRef} />
        </div>
    );
}

function SplashScreen({ onContactClick }) {
    return (
        <div className="splash2">
            <div className="splash-content">
                <h1 className='heading'>Generative AI</h1>
                <p className='subheading'>Utilizing ground breaking technology</p>
                <div className="button-container">
                    <button onClick={onContactClick}>Contact Us</button>
                </div>
            </div>
        </div>
    );
}

function DescriptionSection() {
    return (
        <div className="description-section">
            <div className="text">
                <h2>Advanced Gen AI technologies</h2>
                <p>We design AI-powered applications to tackle intricate business problems, utilizing advanced techniques in knowledge processing, computational analysis, machine learning, perception, reasoning, and decision-making.</p>
            </div>
            <div className="image">
                <img src={app} alt="Application development illustration" />
            </div>
        </div>
    );
}

function KeyFeatures() {
    const features = [
        {
            icon: "path_to_icon1",
            title: "AI DISCOVERY",
            description: "Interest to Discovery: Generative AI Ecosystem and Implications for My Business."
        },
        {
            icon: "path_to_icon2",
            title: "AI LAUNCHPAD",
            description: "Launchpad to Innovation: Evidence-Based Exploration and Deployment."
        },
        {
            icon: "path_to_icon3",
            title: "AI ADOPTION",
            description: "Insight to Impact: Rapid Scaling and Adoption in My Organization."
        }
    ];

    return (
        <div className="features-section">
            <h2>What we do</h2>
            <div className="feature-grid-container">
                <div className="feature-grid">
                    {features.map((feature, index) => (
                        <div key={index} className="feature-item">
                            <h3>{feature.title}</h3>
                            <p>{feature.description}</p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default MobileDev;
