import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import TabBar from './components/TabBar/TabBar';  // Ensure this path is correct
import HomePage from './components/HomePage/HomePage'; // Ensure this path matches your HomePage component location
import AboutUsPage from './components/AboutUsPage/AboutUsPage'; // Ensure this path matches your AboutUsPage component location
import BigDataAIPage from './components/BigDataAIPage/BigDataAIPage'; // Ensure this path matches your BigDataAIPage component location
import MobileDevPage from './components/MobileDevPage/MobileDev'; // Ensure this path matches your BigDataAIPage component location
import ContactPage from './components/ContactPage/ContactPage';
import FooterBar from './components/FooterBar/FooterBar';
import HealthCarePage from './components/HealthcarePage/HealthcarePage'
import LifeSciencePage from './components/LifeSciencePage/LifeSciencePage'
import AIPage from './components/AIPage/AIPage'

function App() {
  return (
    <Router>
      <div>
        <TabBar />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/about" element={<AboutUsPage />} />
          <Route path="/bigdata" element={<BigDataAIPage />} />
          <Route path="/genai" element={<MobileDevPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/healthcare" element={<HealthCarePage />} />
          <Route path="/lifesciences" element={<LifeSciencePage />} />
          <Route path="/aiml" element={<AIPage />} />
          {/* Add more <Route> components here for additional pages */}
        </Routes>
        <FooterBar />
      </div>
    </Router>
  );
}

export default App;
