import React, { useRef } from 'react'; // Import useRef
import './HomePage.css'; 
import Splash from '../../assets/datanetworking.jpg'
import BigData from '../../assets/bigdata.jpeg'
import { MdOutlineDataExploration, MdOutlineMobileFriendly } from 'react-icons/md'; 
import ContactForm from '../ContactForm/ContactForm';
import Phone from '../../assets/becoming-data-ready-for-gen-ai-wf1961200_banner.jpeg'
import AI from '../../assets/bid.png'
import GenAI from '../../assets/AI coverage 2.jpg'

function HomePage() {
    // Create a reference to the ContactForm
    const contactFormRef = useRef(null);

    // Function to scroll to the ContactForm
    const scrollToContact = () => {
        contactFormRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <div className="home">
            <SplashScreen onContactClick={scrollToContact} />
            <FeatureSection />
            <ServicesSection />
            <ContactForm ref={contactFormRef} />
        </div>
    );
}

function SplashScreen({ onContactClick }) {
    return (
        <div className="splash-screen">
            <div className="left-content">
                <h1 className='left-text'>Revolutionizing industries with artificial intelligence</h1>
                <div className="button-container1">
                    <button onClick={onContactClick}>CONTACT US</button>
                </div>
            </div>
        </div>
    );
}

function FeatureSection() {
    return (
        <div className="feature-section">
            <div className="feature-left">
                <img src={BigData} alt="Feature" />
            </div>
            <div className="feature-right">
                <h2>Discover all the possibilities 
with Ethereon AI</h2>
                <p>Accelerate your business outcomes and thrive in our interconnected, data-driven world. At EthereonAI, we prioritize data from the ground up—both at the edge and in the cloud. Our solutions bring the power of the cloud directly to your doorstep. We empower your business to harness unique advantages through AI, opening up opportunities across your entire enterprise. Convert data into intelligence, and intelligence into insight—then into decisive action, all while enhancing safety and security. With EthereonAI, advance the way you live and work, leveraging artificial intelligence solutions that redefine efficiency and innovation.

</p>
                <a href="/about" className="feature-link">LEARN MORE</a>
            </div>
        </div>
    );
}

function ServicesSection() {
    return (
        <div className="services-section">
            <h2 className='services-title'>Our Services</h2>
            <div className="service-container">
                <div className="service-item1">
                    <img src={AI} alt="Mobile Application Development" />
                    <h3>Big Data & Analytics</h3>
                    <p>Unlocking potential with intelligence and analytics.</p>
                </div>
                <div className="service-item1">
                    <img src={Phone} alt="Mobile Application Development" />
                    <h3>AI & ML</h3>
                    <p>Creating innovative and predictive solutions.</p>
                </div>
                <div className="service-item1">
                    <img src={GenAI} alt="Big Data & AI" />
                    <h3>Generative AI</h3>
                    <p>Utilizing ground breaking technology.</p>
                </div>
            </div>
        </div>
    );
}


function IndustrySection() {
    return (
        <div className="industry-section">
            <h2>Industries We Serve</h2>
            <div className="industries-list">
                <div className="industry-item">
                    <h3>Healthcare</h3>
                    <p>Improving patient care through innovation.</p>
                </div>
                <div className="industry-item">
                    <h3>Life Sciences</h3>
                    <p>Advancing scientific discovery for a better tomorrow.</p>
                </div>
            </div>
        </div>
    );
}

function ExploreSection() {
    return (
        <div className="explore-section">
            <h2>Explore More</h2>
            <p>Discover additional resources, insights, and opportunities to connect with us.</p>
            <a href="#more-info" className="explore-link">Learn More</a>
        </div>
    );
}

export default HomePage;
