import React, { useState, forwardRef } from 'react';
import './ContactForm.css';
import emailjs from 'emailjs-com';

// Using forwardRef to allow the parent component to reference a DOM element inside this component
const ContactForm = forwardRef((props, ref) => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    message: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  
    const templateParams = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: formData.email,
      message: formData.message
    };
  
    emailjs.send('service_ui056gm', 'template_ozodhmr', templateParams, 'VYhDHV9gdH3r5IHHG')
      .then((result) => {
          console.log('Email successfully sent!', result.text);
          // Reset form data here
          setFormData({
            firstName: '',
            lastName: '',
            email: '',
            message: ''
          });
      }, (error) => {
          console.log('Failed to send the email.', error.text);
      });
  };
  
  // Apply the forwarded ref to the outer div of the form
  return (
    <div ref={ref} className="contact-form">
      <div className='left'>
        <h1>Get in Touch</h1>
        <p>Excited to collaborate with our AI solutions? Share your details and expect a prompt response. We are eager to connect!</p>
      </div>
      <div className='right'>
        <form onSubmit={handleSubmit}>
          <label>
            First Name (required)
            <input
              type="text"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              required
            />
          </label>
          <label>
            Last Name (required)
            <input
              type="text"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              required
            />
          </label>
          <label>
            Email (required)
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </label>
          <label>
            Message (required)
            <textarea
              name="message"
              value={formData.message}
              onChange={handleChange}
              required
            />
          </label>
          <button type="submit">Send</button>
        </form>
      </div>
    </div>
  );
});

export default ContactForm;
