import React, { useRef } from 'react'; // Import useRef
import './AIPage.css';
import BigData from '../../assets/bigdata.jpeg'
import BigDataSub from '../../assets/bigdatasub.jpg'
import ContactForm from '../ContactForm/ContactForm';

function MobileDev() {
    const contactFormRef = useRef(null); // Create a reference

    const scrollToContact = () => {
        contactFormRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <div className="big-data-ai">
            <SplashScreen onContactClick={scrollToContact} />
            <DescriptionSection />
            <KeyFeatures />
            <ContactForm ref={contactFormRef} />
        </div>
    );
}

function SplashScreen({ onContactClick }) {
    return (
        <div className="splash3">
            <div className="splash-content">
                <h1 className='heading'>Big Data & AI</h1>
                <p className='subheading'>Creating innovative and predictive solutions</p>
                <div className="button-container">
                    <button onClick={onContactClick}>Contact Us</button>
                </div>
            </div>
        </div>
    );
}

function DescriptionSection() {
    return (
        <div className="description-section">
            <div className="text">
                <h2>AI & ML</h2>
                <p>Artificial Intelligence (AI) and Machine Learning (ML) empower your organization to operate swiftly and effectively, enhancing efficiency across various industries. By applying innovative approaches, AI solutions enable you to address complex challenges and optimize costs through Generative AI and robotic process automation.
                <br></br>
                <br></br>
Modern business models leverage Generative AI, computer vision, natural language processing, and speech recognition to automate tasks. AI and ML solutions allow your organization to streamline operations, monitor business performance, accelerate fraud detection and mitigate security risks, thereby delivering significant business value.</p>
            </div>
            <div className="image">
                <img src={BigDataSub} alt="Big Data and AI" />
            </div>
        </div>
    );
}

function KeyFeatures() {
    const features = [
        {
            icon: "path_to_icon1",
            title: "ADVANCED ANALYTICS WITH ACTIONABLE INSIGHTS",
            description: "AI allows businesses to design products based on actionable insights hidden in their pile of structured and unstructured data."
        },
        {
            icon: "path_to_icon2",
            title: "INTELLIGENT AUTOMATION",
            description: "Take information processing to the next level with intelligent automation."
        },
        {
            icon: "path_to_icon3",
            title: "MLOPS AND ML PLATFORM",
            description: "Experimentation infrastructure is highly important for AI product implementation."
        },
        {
            icon: "path_to_icon4",
            title: "GENERATIVE AI",
            description: ". You can reach business productivity faster and be more successful than your competition with Generative AI solutions."
        }
    ];

    return (
        <div className="features-section">
            <h2>What we do</h2>
            <div className="feature-grid-container">
                <div className="feature-grid">
                    {features.map((feature, index) => (
                        <div key={index} className="feature-item">
                            <h3>{feature.title}</h3>
                            <p>{feature.description}</p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default MobileDev;
