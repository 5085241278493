import React, { useRef } from 'react'; // Import useRef
import './LifeSciencePage.css';
import ls from '../../assets/ls.jpg';
import ContactForm from '../ContactForm/ContactForm';

function LifeSciencePage() {
    const contactFormRef = useRef(null); // Create a reference

    const scrollToContact = () => {
        contactFormRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <div className="big-data-ai">
            <SplashScreen onContactClick={scrollToContact} />
            <DescriptionSection />
            <KeyFeatures />
            <ContactForm ref={contactFormRef} />
        </div>
    );
}

function SplashScreen({ onContactClick }) {
    return (
        <div className="splash">
            <div className="splash-content">
                <h1 className='heading'>Life Science Solutions</h1>
                <p className='subheading'>Unlock drug and pharma intelligence with analytics and AI</p>
                <div className="button-container">
                    <button onClick={onContactClick}>Contact Us</button>
                </div>
            </div>
        </div>
    );
}

function DescriptionSection() {
    return (
        <div className="description-section">
            <div className="text">
                <h2>Orchestrating success in pharma and life sciences technology</h2>
                <p>Utilize advanced software solutions to orchestrate precision in drug discovery, clinical trials, and personalized medicine.</p>
            </div>
            <div className="image">
                <img src={ls} alt="Life Sciences and Pharma" />
            </div>
        </div>
    );
}

function KeyFeatures() {
    const features = [
        {
            icon: "path_to_icon1",
            title: "Pharma",
            description: "Improve patient recruitment and retention in trials and optimize drug development."
        },
        {
            icon: "path_to_icon2",
            title: "BioChem",
            description: "Accelerate drug discovery and optimize and bring predictability to bioprocesses."
        },
        {
            icon: "path_to_icon3",
            title: "Medical Devices",
            description: "Accelerate drug discovery and modernize manufacturing operations."
        },
        {
            icon: "path_to_icon4",
            title: "Life Sciences",
            description: "Enhance infectious disease research and simplify access to knowledge resources."
        }
    ];

    return (
        <div className="features-section">
            <h2>What we do</h2>
            <div className="feature-grid-container">
                <div className="feature-grid">
                    {features.map((feature, index) => (
                        <div key={index} className="feature-item">
                            <h3>{feature.title}</h3>
                            <p>{feature.description}</p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default LifeSciencePage;
