import React from 'react';
import './AboutUsPage.css'; // Make sure to update the CSS file accordingly
import Team from '../../assets/team.jpg'
import BackgroundImage from '../../assets/2.png'; // Path to your background image

function AboutUsPage() {
    return (
        <div className="about-us">
            <div className="intro-section">
                <div className="left">
                    <h1>Welcome to Ethereon AI</h1>
                    <p>Unlock the limitless potential of our AI solutions tailored for diverse industries. Whether revolutionizing healthcare, advancing life sciences, or empowering businesses, Ethereon AI is here to transform your operations.
<br></br>
Embrace innovation. Embrace Ethereon AI.</p>
                </div>
                <div className="right">
                    <img src={Team} alt="Team" />
                </div>
            </div>
            <div className="who-we-are-section">
                <div className="left">
                    <h2><strong>Who</strong> we are</h2>
                </div>
                <div className="right">
                    <p>As digital advisors and technology providers, we operate at the forefront of innovation. We specialize in generative AI and data management, delivering quality and speed that exceed our clients' expectations.
</p>
                </div>
            </div>
            <div className="cta-section" style={{ backgroundImage: `url(${BackgroundImage})` }}>
                <h2>Get started today!</h2>
                <a href="/contact" className="">CONNECT</a>
            </div>
        </div>
    );
}

export default AboutUsPage;
