import React from 'react';
import './ContactPage.css';  // Make sure to create a corresponding CSS file for styling
import Splash from '../../assets/datanetworking.jpg'
import BigData from '../../assets/datanetworking.jpg'
import { MdOutlineDataExploration, MdOutlineMobileFriendly } from 'react-icons/md'; 
import ContactForm from '../ContactForm/ContactForm';

function ContactPage() {
    return (
        <div>
            <div className='splash-main-img'>
                <h1>Connect with us!</h1>
                <p>Let us know if you are interested in any services or have any questions.</p>
            </div>
            <div className="home">
                <ContactForm />
            </div>
        </div>
    );
}

export default ContactPage;
